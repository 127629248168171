import React from 'react'

export default function StarsIcon() {
  return (
    <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5973 15.5002C11.419 15.5002 11.2417 15.4431 11.089 15.3292L7.50009 12.6388L3.91113 15.3292C3.76244 15.441 3.58374 15.5008 3.40056 15.5002C3.21738 15.4995 3.03909 15.4384 2.89114 15.3255C2.74305 15.2132 2.63259 15.055 2.57548 14.8734C2.51837 14.6917 2.51752 14.4959 2.57305 14.3137L3.91157 9.79803L0.354822 7.17819C0.207417 7.06448 0.0979459 6.9052 0.0418926 6.72289C-0.0141608 6.54057 -0.0139588 6.34445 0.0424702 6.16226C0.0993442 5.98052 0.209422 5.82205 0.357147 5.70926C0.504871 5.59647 0.682771 5.53506 0.865703 5.53371L5.27083 5.52679L6.67333 1.12319C6.73121 0.941786 6.84222 0.78401 6.99064 0.672207C7.13906 0.560405 7.31737 0.500244 7.50031 0.500244C7.68326 0.500244 7.86156 0.560405 8.00998 0.672207C8.15841 0.78401 8.26941 0.941786 8.32729 1.12319L9.70597 5.52679L14.1336 5.53371C14.3167 5.53479 14.4949 5.59617 14.6428 5.70913C14.7907 5.82209 14.9007 5.98088 14.9573 6.16295C15.0139 6.34503 15.0142 6.54112 14.9581 6.72337C14.9021 6.90562 14.7925 7.06476 14.6449 7.17819L11.0882 9.79803L12.4267 14.3137C12.4823 14.4958 12.4816 14.6917 12.4246 14.8733C12.3675 15.055 12.2571 15.2132 12.109 15.3255C11.9606 15.4393 11.7813 15.5005 11.5973 15.5002Z" fill="#DBBF6E"/>
<path d="M36.5973 15.5002C36.419 15.5002 36.2417 15.4431 36.089 15.3292L32.5001 12.6388L28.9111 15.3292C28.7624 15.441 28.5837 15.5008 28.4006 15.5002C28.2174 15.4995 28.0391 15.4384 27.8911 15.3255C27.743 15.2132 27.6326 15.055 27.5755 14.8734C27.5184 14.6917 27.5175 14.4959 27.573 14.3137L28.9116 9.79803L25.3548 7.17819C25.2074 7.06448 25.0979 6.9052 25.0419 6.72289C24.9858 6.54057 24.986 6.34445 25.0425 6.16226C25.0993 5.98052 25.2094 5.82205 25.3571 5.70926C25.5049 5.59647 25.6828 5.53506 25.8657 5.53371L30.2708 5.52679L31.6733 1.12319C31.7312 0.941786 31.8422 0.78401 31.9906 0.672207C32.1391 0.560405 32.3174 0.500244 32.5003 0.500244C32.6833 0.500244 32.8616 0.560405 33.01 0.672207C33.1584 0.78401 33.2694 0.941786 33.3273 1.12319L34.706 5.52679L39.1336 5.53371C39.3167 5.53479 39.4949 5.59617 39.6428 5.70913C39.7907 5.82209 39.9007 5.98088 39.9573 6.16295C40.0139 6.34503 40.0142 6.54112 39.9581 6.72337C39.9021 6.90562 39.7925 7.06476 39.6449 7.17819L36.0882 9.79803L37.4267 14.3137C37.4823 14.4958 37.4816 14.6917 37.4246 14.8733C37.3675 15.055 37.2571 15.2132 37.109 15.3255C36.9606 15.4393 36.7813 15.5005 36.5973 15.5002Z" fill="#DBBF6E"/>
<path d="M61.5973 15.5002C61.419 15.5002 61.2417 15.4431 61.089 15.3292L57.5001 12.6388L53.9111 15.3292C53.7624 15.441 53.5837 15.5008 53.4006 15.5002C53.2174 15.4995 53.0391 15.4384 52.8911 15.3255C52.743 15.2132 52.6326 15.055 52.5755 14.8734C52.5184 14.6917 52.5175 14.4959 52.573 14.3137L53.9116 9.79803L50.3548 7.17819C50.2074 7.06448 50.0979 6.9052 50.0419 6.72289C49.9858 6.54057 49.986 6.34445 50.0425 6.16226C50.0993 5.98052 50.2094 5.82205 50.3571 5.70926C50.5049 5.59647 50.6828 5.53506 50.8657 5.53371L55.2708 5.52679L56.6733 1.12319C56.7312 0.941786 56.8422 0.78401 56.9906 0.672207C57.1391 0.560405 57.3174 0.500244 57.5003 0.500244C57.6833 0.500244 57.8616 0.560405 58.01 0.672207C58.1584 0.78401 58.2694 0.941786 58.3273 1.12319L59.706 5.52679L64.1336 5.53371C64.3167 5.53479 64.4949 5.59617 64.6428 5.70913C64.7907 5.82209 64.9007 5.98088 64.9573 6.16295C65.0139 6.34503 65.0142 6.54112 64.9581 6.72337C64.9021 6.90562 64.7925 7.06476 64.6449 7.17819L61.0882 9.79803L62.4267 14.3137C62.4823 14.4958 62.4816 14.6917 62.4246 14.8733C62.3675 15.055 62.2571 15.2132 62.109 15.3255C61.9606 15.4393 61.7813 15.5005 61.5973 15.5002Z" fill="#DBBF6E"/>
<path d="M86.5973 15.5002C86.419 15.5002 86.2417 15.4431 86.089 15.3292L82.5001 12.6388L78.9111 15.3292C78.7624 15.441 78.5837 15.5008 78.4006 15.5002C78.2174 15.4995 78.0391 15.4384 77.8911 15.3255C77.743 15.2132 77.6326 15.055 77.5755 14.8734C77.5184 14.6917 77.5175 14.4959 77.573 14.3137L78.9116 9.79803L75.3548 7.17819C75.2074 7.06448 75.0979 6.9052 75.0419 6.72289C74.9858 6.54057 74.986 6.34445 75.0425 6.16226C75.0993 5.98052 75.2094 5.82205 75.3571 5.70926C75.5049 5.59647 75.6828 5.53506 75.8657 5.53371L80.2708 5.52679L81.6733 1.12319C81.7312 0.941786 81.8422 0.78401 81.9906 0.672207C82.1391 0.560405 82.3174 0.500244 82.5003 0.500244C82.6833 0.500244 82.8616 0.560405 83.01 0.672207C83.1584 0.78401 83.2694 0.941786 83.3273 1.12319L84.706 5.52679L89.1336 5.53371C89.3167 5.53479 89.4949 5.59617 89.6428 5.70913C89.7907 5.82209 89.9007 5.98088 89.9573 6.16295C90.0139 6.34503 90.0142 6.54112 89.9581 6.72337C89.9021 6.90562 89.7925 7.06476 89.6449 7.17819L86.0882 9.79803L87.4267 14.3137C87.4823 14.4958 87.4816 14.6917 87.4246 14.8733C87.3675 15.055 87.2571 15.2132 87.109 15.3255C86.9606 15.4393 86.7813 15.5005 86.5973 15.5002Z" fill="#DBBF6E"/>
<path d="M111.597 15.5002C111.419 15.5002 111.242 15.4431 111.089 15.3292L107.5 12.6388L103.911 15.3292C103.762 15.441 103.584 15.5008 103.401 15.5002C103.217 15.4995 103.039 15.4384 102.891 15.3255C102.743 15.2132 102.633 15.055 102.575 14.8734C102.518 14.6917 102.518 14.4959 102.573 14.3137L103.912 9.79803L100.355 7.17819C100.207 7.06448 100.098 6.9052 100.042 6.72289C99.9858 6.54057 99.986 6.34445 100.042 6.16226C100.099 5.98052 100.209 5.82205 100.357 5.70926C100.505 5.59647 100.683 5.53506 100.866 5.53371L105.271 5.52679L106.673 1.12319C106.731 0.941786 106.842 0.78401 106.991 0.672207C107.139 0.560405 107.317 0.500244 107.5 0.500244C107.683 0.500244 107.862 0.560405 108.01 0.672207C108.158 0.78401 108.269 0.941786 108.327 1.12319L109.706 5.52679L114.134 5.53371C114.317 5.53479 114.495 5.59617 114.643 5.70913C114.791 5.82209 114.901 5.98088 114.957 6.16295C115.014 6.34503 115.014 6.54112 114.958 6.72337C114.902 6.90562 114.792 7.06476 114.645 7.17819L111.088 9.79803L112.427 14.3137C112.482 14.4958 112.482 14.6917 112.425 14.8733C112.368 15.055 112.257 15.2132 112.109 15.3255C111.961 15.4393 111.781 15.5005 111.597 15.5002Z" fill="#DBBF6E"/>
</svg>

  )
}
