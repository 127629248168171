// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3_7BOVl0hUTJw9lM4KqU_FrBefKx54qI",
  authDomain: "corso-landing-page.firebaseapp.com",
  projectId: "corso-landing-page",
  storageBucket: "corso-landing-page.appspot.com",
  messagingSenderId: "553794159620",
  appId: "1:553794159620:web:a89f2c59d9405a19f52151",
  measurementId: "G-FTHNZDE5WH"
};

// Initialize Firebase

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  export const db = getFirestore(app)
  export const storage = getStorage(app);
