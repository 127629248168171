

import React from 'react'


export default function ValuesBanner({text1, italics2}) {
 return (
   <div className='values-banner'>
       <p className='values-banner-text'>
       {text1}
       </p>
       <p className='values-banner-italics'>
        {italics2}
       </p>
   </div>
 )
}


