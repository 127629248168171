import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { NavHashLink } from 'react-router-hash-link';
import RequestInfoBtn from '../components/RequestInfoBtn'
import 'video.js/dist/video-js.css';
import PlayIcon from '../icons/PlayIcon';
export default function CommercialBlock({storage}) {

  const videoRef=useRef()
  const [controls, setControls]=useState(false)
  const max_width = useMediaPredicate("(max-width: 960px)");
  const handleControls=()=>{
    
    if (controls===false){
      setControls(true)
      videoRef.current.play()
    }else if (controls===true){
        setControls(false)
        videoRef.current.pause()
      }
    } 
  
  const [commercialVid, setCommercialVid]=useState();
  const videoJsOptions={
      controls:true,
      sources:[{
        src:`${commercialVid}#t=0.001`,
        type:'video/mp4'
      }]
  }
 

  const getCommercialVideo=(file_name)=>{
    getDownloadURL(ref(storage, `corso-landing-page/commercial_page/${file_name}`))
    .then((url) => {
      setCommercialVid(url) 
    })
    .catch((error) => {
      console.log(error)
    });
  }

  useEffect(()=>{
    getCommercialVideo('commercial_video.mp4')
  },[])
 return (
<div className='commercial-block'>
     <div className='commercial-block-body div-copy' >
      <div>
       <p className='commercial-video'>
         commercial video
       </p>
       <h2 className='the-latest-of-corso'>
         The Latest of Corso
       </h2>
       </div>
       {max_width?<>
       </>:
       <>
       <NavHashLink to='#contactForm' data-testid='commercial-request-info-btn'>
          <RequestInfoBtn />
       </NavHashLink>
        
       </>}
     </div>

     
     {commercialVid?<>
      <div className='vid-container' onClick={handleControls} data-testid='commercial-video'>
      <video alt="" controls={controls} ref={videoRef} onClick={handleControls} name='testimonial_play' className='commercial-vid' src={`${commercialVid}#t=0.001`} data-testid='commercial-video'/>
          <PlayIcon className='play-icon-commercial'   onClick={handleControls} style={controls?{display:'none', zIndex:'-999 !important', fill:'white'}:{fill:'white', display:'block'}} data-testid='commercial-video'/>
      </div>
     </>:<>
     <img alt="" className='commercial-block-img' src="https://static.overlay-tech.com/assets/dcfe2311-15c3-4a50-8fc1-d668cb218add.png" />
     </>}
     
     {max_width?<>
      <NavHashLink to='#contactForm' data-testid='commercial-request-info-btn'>
          <RequestInfoBtn />
       </NavHashLink>
     </>:<></>}
     
    

  </div>  

  )
}




