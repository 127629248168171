import React from "react";


export default function EmailIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
        d="M0.078125 2.55469C0.235653 1.86444 0.622899 1.24815 1.17644 0.806732C1.72998 0.365318 2.41701 0.124952 3.125 0.125H21.875C22.583 0.124952 23.27 0.365318 23.8236 0.806732C24.3771 1.24815 24.7643 1.86444 24.9219 2.55469L12.5 10.1469L0.078125 2.55469ZM0 4.33906V15.4391L9.06719 9.87969L0 4.33906ZM10.5641 10.7969L0.298438 17.0891C0.552073 17.6239 0.952385 18.0757 1.45278 18.3918C1.95318 18.708 2.53309 18.8756 3.125 18.875H21.875C22.4668 18.8751 23.0465 18.7072 23.5466 18.3908C24.0467 18.0743 24.4467 17.6224 24.7 17.0875L14.4344 10.7953L12.5 11.9781L10.5641 10.7953V10.7969ZM15.9328 9.88125L25 15.4391V4.33906L15.9328 9.87969V9.88125Z"
        {...props}
      />
    </svg>
  );
}
