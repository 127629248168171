import { getDownloadURL, ref } from 'firebase/storage'
import React, { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import {motion} from "framer-motion";
import { ArrowUpRight } from 'phosphor-react';
export default function SocialHandle({storage}) {
    const max_width = useMediaPredicate("(max-width: 760px)");
    const [image1, setimage1]=useState()
    const [image2, setimage2]=useState()
    const [image3, setimage3]=useState()
    const [image4, setimage4]=useState()
    const [image5, setimage5]=useState()
    const getPhotos=(file_name)=>{
      getDownloadURL(ref(storage, `corso-landing-page/social_handle/${file_name}`))
      .then((url) => {
        switch(file_name){
          case "social_image1.png":
            setimage1(url)
            break;
          case "social_image2.png":
            setimage2(url)
            break;
          case "social_image3.png":
            setimage3(url)
            break; 
          case "social_image4.png":
            setimage4(url)
            break; 
          case "social_image5.png":
            setimage5(url)
            break; 
        }
      })
      .catch((error) => {
        // Handle any errors
      });
    };
  
    const getAllPhotos=()=>{
      getPhotos('social_image1.png')
      getPhotos('social_image2.png')
      getPhotos('social_image3.png')
      getPhotos('social_image4.png')
      getPhotos('social_image5.png')
    };
    useEffect(()=>{
      getAllPhotos()
    },[])
  
  return (<>

    {max_width?<>
      <div className='social-handle-container'>
        <div className='social-handle'>
          <a className='social-handle-text' href='https://www.instagram.com/corsoatlanta/?hl=en' rel="noreferrer" target="_blank" data-testid='social-handle-btn'><i>@corsoatlanta</i></a>
        </div>
        <div className='social-image-banner'>
          <motion.a  className='ig-link a-copy' href='https://www.instagram.com/p/CoxI8uAMcP5/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-1'>
            <img alt='' src={image1} style={{borderRadius:'8px'}} className='socialHandleImg'/>
          </motion.a>
        </div>      
        <a  href='https://www.instagram.com/corsoatlanta/?hl=en' rel="noreferrer" target="_blank" whileHover={{scale:1.05,duration:.05}} className="view-ig-btn a-copy" data-testid='mobile-view-instagram-btn'>
          <p className="view-ig-btn-text" >view instagram</p>
          <ArrowUpRight size={20} color='black' fill='bold'/>
        </a>
      </div>
     </>:<>
    
    <div className='social-handle-container'>
        <div className='social-handle'>
         <a className='social-handle-text' href='https://www.instagram.com/corsoatlanta/?hl=en' rel="noreferrer" target="_blank"><i>@corsoatlanta</i></a>
        </div>
        <div className='social-image-banner'>
          <motion.a  className='ig-link a-copy' href='https://www.instagram.com/p/CoxI8uAMcP5/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-2'>
            <img alt='a white orchid' src={image1} className='socialHandleImg' style={{borderRadius:'8px 0px 0px 8px'}}/>
          </motion.a>
          <motion.a className='ig-link a-copy' href='https://www.instagram.com/p/CosBfKOO4BD/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-3'>
            <img alt='A senior couple taking a cooking class at Corso Atlanta' src={image2} className='socialHandleImg'/>
          </motion.a>
          <motion.a className='ig-link a-copy' href='https://www.instagram.com/p/Cnhh_OGrKOW/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-4'>
            <img alt='Corso Atlanta building exterior' src={image3} className='socialHandleImg'/>
          </motion.a>
          <motion.a className='ig-link a-copy' href='https://www.instagram.com/p/Cos9QczrBXg/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-5'>
            <img alt='A red cocktail with blackberries and mint' src={image4} className='socialHandleImg'/>
          </motion.a>
          <motion.a className='ig-link a-copy' href='https://www.instagram.com/p/CpVMD8Ptf-Z/' rel="noreferrer" target='_blank' whileHover={{opacity:.8, duration:.05}} data-testid='instagram-photo-6'> 
            <img alt='a purple orchid' src={image5} className='socialHandleImg' style={{borderRadius:'0px 8px 8px 0px'}}/>
          </motion.a>
        
        </div>
       
    </div>
   </>}
    </>
  )
}
