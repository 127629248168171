import { List } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import {AnimatePresence, motion} from "framer-motion";
import { NavHashLink } from "react-router-hash-link";
import HamburgerMenuExpanded from "./HamburgerMenuExpanded";

export default function NavBar({navbarBackground}) {
 const max_width = useMediaPredicate("(min-width: 1000px)");
const [menuIconWeight, setMenuIconWeight]=useState('thin');
const [expandMenu, setExpandMenu]=useState(false);

const handleExpand=()=>{
  setExpandMenu(!expandMenu)
}

 return (
   <>
     {max_width ? (
       <>
           <div className='navbar' style={{backgroundColor:`${navbarBackground}`}}>
               <img alt="" className='corso-logo' src="https://static.overlay-tech.com/assets/83ed8b1e-1e8d-43a8-bdbc-852e41f78bf4.svg" />
               <div className='navbar-buttons'>
                   <motion.a href="https://corsoatlanta.com/our-difference/" target='_blank'  whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='our-difference-navbar-link'>our Difference</motion.a>
                   <motion.a href="https://corsoatlanta.com/residences/" target='_blank' whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='residences-navbar-link'>residences</motion.a>
                   <motion.a href="https://corsoatlanta.com/lifestyle/" target='_blank' whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='lifestyle-navbar-link'>lifestyle</motion.a>
                   <motion.a href="https://corsoatlanta.com/wellness/" target='_blank' whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='wellness-navbar-link'>wellness</motion.a>
                   <motion.a href="https://corsoatlanta.com/blog/" target='_blank' whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='blog-navbar-link'>blog</motion.a>
                   <motion.a href="https://corsoatlanta.com/gallery/" target='_blank' whileHover={!max_width?{}:{ scale:1.05,rotate: .1 }} whileTap={max_width?{}:{ scale: 0.8}} className='div-copy nav-link' data-testid='gallery-navbar-link'>gallery</motion.a>
                   <NavHashLink  to='#contactForm'>
                   <motion.button whileHover={!max_width?{ scale: 1 }:{scale:1.05,duration:.05}} className='navbar-btn' data-testid='request-info-btn-navbar'>
                       <p  className='navbar-btn-text'>request info</p>
                   </motion.button>
                   </NavHashLink>
                   
               </div>
           </div>
       </>
     ) : (
       <div style={{background:'rgba(238, 236, 230, 1)'}}>
         {" "}
      
           <div className="frame7" style={{backgroundColor:`${navbarBackground}`}}>
           <img alt="" className="corso-brandmark-white" src="https://static.overlay-tech.com/assets/c7691df9-f3fc-42ab-a752-4aa1180a5511.svg" />
           <div className="menu-icon" data-testid='open-hamburger-navbar-btn' onClick={handleExpand}>
            <List size={36} weight={menuIconWeight} onMouseEnter={()=>setMenuIconWeight('regular')} onMouseLeave={()=>setMenuIconWeight('thin')} />
           </div>
         
         </div>
         {expandMenu&&<>
            <AnimatePresence>
              <motion.div className="" id="" key={expandMenu?1:2}
              transition={{y: { type: "spring", stiffness: 300, damping: 30 },opacity: { duration: 0.2 }}}
              initial={{ y: -300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 300 }}
              >
                <HamburgerMenuExpanded className='hamburger-menu-expanded' navbarBackground={navbarBackground}/>
              </motion.div>
            </AnimatePresence>
         </>}
        
       </div>
     )}
   </>
 );
}




