import { ArrowUpRight } from "@phosphor-icons/react";
import React, { Fragment, useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { ref, getDownloadURL } from "firebase/storage";
import { NavHashLink } from "react-router-hash-link";
import {motion} from "framer-motion";
export default function AboutUsBlock({storage}) {
    const max_width = useMediaPredicate("(max-width: 760px)");
    const [buttonColor, setButtonColor]=useState({});
    const [image1,setimage1]=useState()
    const [image2,setimage2]=useState()
    const [image3,setimage3]=useState()
    const handlePress=(id)=>{
      setButtonColor({
        ...buttonColor,
        [id]:!buttonColor[id],
      })
    }
    const handleMouseUp=()=>{
      setButtonColor({})
    }


    const getAboutUsImages=(file_name)=>{
      getDownloadURL(ref(storage, `corso-landing-page/about_us_page/${file_name}`))
      .then((url) => {
        switch(file_name){
            case 'about_us_img1.jpg':
              setimage1(url);
              break;
            case 'wellness_stock_photo.jpg':
              setimage2(url);
              break;
            case 'about_us_img3.jpg':
              setimage3(url);
              break;
        }
      })
      .catch((error) => {
        console.log(error)
      });
    }
    
    const getImages=()=>{
      getAboutUsImages('about_us_img1.jpg')
      getAboutUsImages('wellness_stock_photo.jpg')
      getAboutUsImages('about_us_img3.jpg')
    }
  
  
 const about_us_cards = [
   {
     image:image1,
     title: "Lifestyle",
     subtitle:"Our communities are intentionally designed for residents and their guests to experience beauty, connection, and happiness daily.",
     link: "https://corsoatlanta.com/lifestyle/",
     alt_tag:'Two women laughing in conversation at a cafe table.'
   },
   {
     image:image2,
     title: "Wellness",
     subtitle:"We enrich your mental, physical, and social wellbeing through a range of classes, on-site therapies, and customizable wellness services.",
     link: "https://corsoatlanta.com/wellness/",
     alt_tag:'A woman being assisted by a personal trainer on a yoga mat.'
   },
   {
     image:image3,
     title: "Flourish",
     subtitle:"No matter their age or ability, each person has stories to share, lessons to learn, and wellness goals to achieve. Here, everyone is invited to flourish.",
     link: "https://corsoatlanta.com/wellness/#:~:text=residents%E2%80%99%20complete%20health%2C-,Flourish,-employs%20evidence%2Dbased",
     alt_tag:'A woman making a salad in a kitchen.'
   },
 ];

    useEffect(()=>{
      getImages()
    },[])
 return (
   <div className="about-us-block">
     <div className=".about-us-header .div-copy">  
       <p className="about-us">about us</p>
       {max_width?<>
        <p className="your-experience">Your Experience</p>
       </>:<>
       <div className="your-experience-div">
        <div>
       <p className="your-experience">Your Experience With Corso</p>
       </div>
       <NavHashLink to='#contactForm' data-testid='about-us-request-info-btn'>
       <motion.button whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}} className="request-info">Request Info</motion.button>
       </NavHashLink>
       </div>
       </>}
     </div>

     <p className="about-us-divider1"></p>
        <div className="about-us-cards">
     {about_us_cards.map((card, i)=><Fragment key={i}>
       <div className="about-us-card" >
       <img alt={card.alt_tag} className="about-us-card-img" src={card.image} />
       <div className="about-us-card-body">
         <div className="about-us-card-header">
           <p className="about-us-card-title">{card.title}</p>
           <p className="about-us-card-sub">{card.subtitle}</p>
         </div>
         <motion.a className="about-us-card-footer a-copy" 
            onMouseDown={()=>handlePress(i)} 
            href={card.link} 
            onMouseUp={()=>handleMouseUp()} 
            style={{backgroundColor: buttonColor[i] ? 'black': 'white'}} 
            target="_blank" 
            rel="noreferrer"
            whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}}
            data-testid={`${card.title}-learn-more-btn`}
            >
          <p className="learn-more-link" style={{color: buttonColor[i] ? 'white': 'black'}} data-testid={`${card.title}-learn-more-btn`}>learn more</p>
          <ArrowUpRight style={{color: buttonColor[i] ? 'white': 'black'}} size={20} />
         </motion.a>
       </div>
     </div>
     <p className="about-us-divider"></p>
     </Fragment>)}
     </div>
     {max_width? <>
      <NavHashLink to='#contactForm' data-testid='about-us-request-info-btn'>
      <button className="request-info">Request Info</button>
      </NavHashLink>
     
     </>:<>
     
     </>}
   
   </div>
 );
}




