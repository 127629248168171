import React from 'react'
import { useMediaPredicate } from 'react-media-hook'
import {motion} from "framer-motion";
export default function Accolades() {
  const max_width=useMediaPredicate("(max-width:760px")
  return (
    <div className='accolades'>
      <div className='accolades-header'>
        <p className='accolades-header-sub p-copy'>a few of</p>
        <p className='accolades-header-main p-copy'>Our Accolades</p>
      </div>
      <div className='accolades-body'>
          <div className='accolades-footer1'>
            {max_width?
            <>
             <p className='accolades-title'>#1 Best Senior Living <br />Community in America</p>
            </>
            :
            <>
             <p className='accolades-title'>#1 Best Senior Living Community in America</p>
            </>}
            <p className='accolades-subtitle1'>Awarded by Seniorly.com</p>
          </div>

         
          <div className='accolades-divider'></div>

          <div className='accolades-footer2'>
            <p className='accolades-footer-title'>
              #1 Independent Living Winner{" "}
            </p>
            <p className='accolades-subtitle2'>
              Awarded by Senior Housing News Architecture
              &amp; Design Awards 2022
            </p>
          </div>

        </div>
    </div>
  )
}
