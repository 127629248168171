import React from 'react'
import { NavHashLink } from 'react-router-hash-link'


export default function mobileMenuExpanded({navbarBackground}) {
 return (
   <div className='expanded-container' style={{backgroundColor:`${navbarBackground}`}}>
   <div className='navbar-buttons-expanded' style={{backgroundColor:`${navbarBackground}`}}>
       <a href='https://corsoatlanta.com/our-difference/' target='_blank' rel='noreferrer'  className='link-expanded' data-testid='difference-mobile-navbar-link'>our difference</a>
       <div className='nav-divider'></div>
       <a href='https://corsoatlanta.com/residences/' target='_blank' rel='noreferrer' className='link-expanded' data-testid='residences-mobile-navbar-link'>residences</a>
       <div className='nav-divider'></div>
       <a href='https://corsoatlanta.com/lifestyle/' target='_blank' rel='noreferrer' className='link-expanded' data-testid='lifestyle-mobile-navbar-link'>lifestyle</a>
       <div className='nav-divider'></div>
       <a href='https://corsoatlanta.com/wellness/' target='_blank' rel='noreferrer' className='link-expanded' data-testid='wellness-mobile-navbar-link'>wellness</a>
       <div className='nav-divider'></div>
       <a href='https://corsoatlanta.com/blog/' target='_blank' rel='noreferrer' className='link-expanded' data-testid='blog-mobile-navbar-link'>blog</a>
       <div className='nav-divider'></div>
       <a href='https://corsoatlanta.com/gallery/' target='_blank' rel='noreferrer' className='link-expanded' data-testid='gallery-mobile-navbar-link'>gallery</a>
       <div className='nav-divider'></div>
       <NavHashLink to='#contactForm' className="request-info-btn" data-testid='request-info-btn-mobile-navbar'>Request Info</NavHashLink>
 </div>
 </div>
 )
}


