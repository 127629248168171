import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect } from 'react'
import { useState } from 'react';
import {validPhone, validEmail } from '../Regex';
import { addDoc, collection } from 'firebase/firestore';
import { useMediaPredicate } from 'react-media-hook';
import { X } from '@phosphor-icons/react';
export default function ShortContactForm({storage, db}) {
  const [corsoBuilding, setCorsoBuilding]=useState();
  const [sendingBtn, setSendingBtn]=useState('submit')
  const [messageSuccess, setMessageSuccess]=useState(false);
  const [phoneAlert, setPhoneAlert]=useState(false);
  const [emailAlert, setEmailAlert]=useState(false);
  const current_date=new Date();
  const max_width = useMediaPredicate("(max-width: 760px)");
  const max_width2 = useMediaPredicate("(max-width: 893px)");
  const getCorsoImg=()=>{
    getDownloadURL(ref(storage, `corso-landing-page/corso_building_lower_res.jpg`))
    .then((url) => {
      setCorsoBuilding(url)
    })
    .catch((error) => {
      // Handle any errors
    });
  };

  const handleEventPush=()=>{
    window.dataLayer.push({
      event_name:'contact_form_short_submitted',
      event:'contact_form_short_submitted'
    })
  }

  const submitContactForm=async(e)=>{
    const sending='sending'
    setSendingBtn(sending)
    e.preventDefault();
    try {
      
    const docRef = await addDoc(collection(db, "contactForms"), {
      full_name: e.target.fullName.value,
      phone_number: e.target.phoneNumber.value,
      email:e.target.email.value,
      contact_date:current_date,
      date_submitted:current_date,
      to:['doemelsydney@gmail.com','referralscorso@corsoatlanta.com','referralscorso@villageparkseniorliving.com'],
      message:{
        subject:'New submission from "Schedule a Call - Short Form" form',
        html:`Full Name: ${e.target.fullName.value}<br> Phone: ${e.target.phoneNumber.value}<br>
          <br> Email: ${e.target.email.value}
          Date of Submission: ${current_date}<br> Page: Corso Landing Page`
      }
    });
      
      handleEventPush()
      const sent='sent'
      setSendingBtn(sent)
      setMessageSuccess(true)
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }


  const validatePhone = (e) =>{
    if (!validPhone.test(e.target.value)) {
       setPhoneAlert(true);
    }else{
      setPhoneAlert(false)
    }
 };
 const validateEmail = (e) => {
  if (!validEmail.test(e.target.value)) {
     setEmailAlert(true);
  }else{
    setEmailAlert(false)
  }
}
 const handleExit=()=>{
  setMessageSuccess(false)
  const sendmessage='submit'
  setSendingBtn(sendmessage)
}

  useEffect(()=>{
    getCorsoImg();
  },[])
  return (
    <div className='short-form-container'>
       <img id='corso_building' alt='Corso Atlanta building exterior' src={corsoBuilding} className='corso-building-img'/>
       <form onSubmit={(e)=>submitContactForm(e)} className='short-form'>
        {messageSuccess?<>
          <div className='success-msg-header-div'>
            <h1 className='success-msg-header'>
            Thank you!
            </h1>
            <X onClick={handleExit} color='rgba(199, 193, 186, 1)' size={32} />
          </div>
          <p className='success-msg-text'>We appreciate you contacting Corso Atlanta. One of our team members will get in touch with you soon. </p>
          <p className='success-msg-text'>In the meantime, feel free to look at our gallery and the amenities our residences provides.</p>
          <p className='success-msg-text'>Have a great day!</p>
          <button  className='submit-contact-btn'>
               <p className='submit-contact-btn-txt'>
                   Sent
               </p>
          </button>
        </>:<>
       
        <div className='short-form-header'>
          <p className='short-form-header-text'>
            Schedule A Call
          </p>
          <p  className='short-form-header-sub'>
            Please fill out our short form so we can set you up with all the information you need.
          </p>
        </div>
          <div className='short-form-body'>
            <div className='short-form-input'>
              <div className='label-div'>
                <label className='' htmlFor='fullName'>Full Name</label>
                {/* alert below is only for styling purposes when another field has an alert */}
                {emailAlert || phoneAlert?<><div style={{height:'14px'}}></div></>:<></>}
              </div>
                <input className='' required type='text' id='fullName' name='fullName' placeholder='Full Name'/>
            </div>
            <div className='short-form-input'>
              <div className='label-div'>
                <label className='' htmlFor='phoneNumber'>Phone Number</label>
                {phoneAlert?<><p style={{color:'red', fontSize:'14px'}}>Enter a valid phone number</p></>:<></>}
                {/* alert below is only for styling purposes when another field has an alert */}
                {emailAlert && !phoneAlert?<><div style={{height:'14px'}}></div></>:<></>}
              </div>
              <input className='' onChange={(e)=>{validatePhone(e)}}  type='tel' id='phoneNumber' name='phoneNumber' defaultValue='' placeholder='Phone Number'/>
            </div>
            <div className='short-form-input'>
              <div className='label-div'>
                <label className='' htmlFor='email'>Email</label>
                {emailAlert?<><p style={{color:'red', fontSize:'14px'}}>Enter a valid email</p></>:<></>}
                {/* alert below is only for styling purposes when another field has an alert */}
                {phoneAlert && !emailAlert?<><div style={{height:'9px'}}></div></>:<></>}
              </div>
              <input className='' onChange={(e)=>{validateEmail(e)}}  type='text' id='email' name='email' defaultValue='' placeholder='Email'/>
            </div>


                <button type='submit'  className='submit-short-form-btn' data-testid='submit-short-form-btn'>
                  <p className='submit-short-form-btn-txt'>
                    {sendingBtn}
                  </p>
                </button>
          </div>
       
   
    </>}
    </form>
  </div>
  )
}
