import React from "react";

export default function MapPinIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 12.5C12.2366 12.5 12.8674 12.255 13.3924 11.765C13.9165 11.2758 14.1786 10.6875 14.1786 10C14.1786 9.3125 13.9165 8.72375 13.3924 8.23375C12.8674 7.74458 12.2366 7.5 11.5 7.5C10.7634 7.5 10.133 7.74458 9.60892 8.23375C9.08392 8.72375 8.82142 9.3125 8.82142 10C8.82142 10.6875 9.08392 11.2758 9.60892 11.765C10.133 12.255 10.7634 12.5 11.5 12.5ZM11.5 25C7.90624 22.1458 5.22231 19.4946 3.44821 17.0463C1.67321 14.5988 0.785706 12.3333 0.785706 10.25C0.785706 7.125 1.86294 4.63542 4.0174 2.78125C6.17098 0.927083 8.66517 0 11.5 0C14.3348 0 16.829 0.927083 18.9826 2.78125C21.1371 4.63542 22.2143 7.125 22.2143 10.25C22.2143 12.3333 21.3272 14.5988 19.5531 17.0463C17.7781 19.4946 15.0937 22.1458 11.5 25Z"
        {...props}
      />
    </svg>
  );
}
