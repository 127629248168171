

import { ArrowUpRight } from '@phosphor-icons/react'
import { getDownloadURL, ref } from 'firebase/storage';
import React, { Fragment, useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import {motion} from "framer-motion";

export default function BlogBlock({storage}) {
  const [blogImage1,setBlogImage1]=useState();
  const [blogImage2,setBlogImage2]=useState();
  const [blogImage3,setBlogImage3]=useState();

  const max_width=useMediaPredicate("(max-width:974px");

  const [buttonColor, setButtonColor]=useState({})
  const handlePress=(id)=>{
   setButtonColor({
     ...buttonColor,
     [id]:!buttonColor[id],
   })
  }
  const handleMouseUp=()=>{
   setButtonColor({})
  };

  const getPhotos=(file_name)=>{
    getDownloadURL(ref(storage, `corso-landing-page/blog_page/${file_name}`))
    .then((url) => {
      switch(file_name){
        case "holiday_fitness.png":
          setBlogImage1(url)
          break;
        case "matzi_bear.png":
          setBlogImage2(url)
          break;
        case "values_tradition.png":
          setBlogImage3(url)
          break; 
      }
    })
    .catch((error) => {
      // Handle any errors
    });
  };

  const getAllPhotos=()=>{
    getPhotos('holiday_fitness.png')
    getPhotos('matzi_bear.png')
    getPhotos('values_tradition.png')
  };

   const blog_posts=[
       {
           image:blogImage1,
           date:'January 9, 2023',
           title:'Holiday Fitness',
           author:'Written by Bradford Bailey',
           link:"https://corsoatlanta.com/blog/holiday-fitness/",
           alt_tag:'A senior couple doing yoga together'
       },
       {
           image:blogImage2,
           date:'January 2, 2023',
           title:'Matzi the Bear',
           author:'Written by Beatrix Schwartz',
           link:"https://corsoatlanta.com/blog/matzi-the-bear/",
           alt_tag:'An aged black and white photo of a little girl with a bear'
       },
       {
           image:blogImage3,
           date:'December 28, 2022',
           title:'Values & Tradition',
           author:'Written by Clarissa Rice',
           link:'https://corsoatlanta.com/blog/values-tradition/',
           alt_tag:'A senior woman posing and smiling'
       },
   ]

   useEffect(() => {
     getAllPhotos()
   }, [])
   
 return (
   <div className='blog-block'>
     <div className='blog-block-header div-copy'>
       <p className='the-blog' >the blog</p>
       {max_width?<>
        <p className='recent-posts' >Recent Posts</p>
        </>:
     <div className='expanded-blog-header'>
        <div>
          <p className='recent-posts' >Recent Posts</p>
        </div>
        <motion.a whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}}
        className='blog-link p-copy' href='https://corsoatlanta.com/blog/'
        target='_blank' rel="noreferrer"
        data-testid='view-blog-btn'
        >
          <p className='view-the-blog'>view the blog</p>
        </motion.a>
      </div>
        }
     </div>
    
    <div className='blog-posts-div'>
        {blog_posts.map((post, i)=><Fragment key={i}>
        <div className='blog-post'>
            <img alt={post.alt_tag} className='blog-image' src={post.image}/>
            <div className='blog-post-contents'>
            <div className='blog-post-body'>
                <p className='blog-date'>{post.date}</p>
                <p className='blog-title'>{post.title}</p>
                <p className='blog-description'>{post.author}</p>
            </div>
            <motion.a className='blog-post-footer a-copy'
            whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}} href={post.link} target='_blank'
            rel="noreferrer" onMouseDown={()=>handlePress(i)} onMouseUp={()=>{handleMouseUp()}}
            style={{backgroundColor: buttonColor[i]? 'black':'white'}} data-testid={`blog-${post.title}-btn`}
            >
                <p className='blog-description2' style={{color: buttonColor[i]? 'white':'black'}}>read more</p>
                <div className='blog-div'>
                <ArrowUpRight size={25} style={{color: buttonColor[i]? 'white':'black'}}/>
                </div>
            </motion.a>
            </div>
        </div>
        </Fragment>)}
       </div>
    {max_width?<>
      <div className='blog-link' data-testid='view-blog-btn'>
       <p className='view-the-blog'>view the blog</p>
     </div>
    </>:<></>}
     
   </div>
 )
}


