import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div style={{position:'relative'}}>
            <div style={{height:'100vh', width:'100vw',zIndex:'999',background:'white', padding:'100px'}}>
                <h1>Oops! You seem to be lost.</h1>
                <div style={{display:'flex', flexDirection:'row'}}>
                <p style={{margin:'0px 10px 0px 0px'}}>Go to</p>
                <Link to='/'>Corso Atlanta Landing Page</Link>
            </div>
            </div>
        </div>
    )
}
