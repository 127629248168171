import React from "react";

export default function PhoneIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        {...props}
        d="M2.9458 0.797629C3.2192 0.524643 3.54749 0.312877 3.90892 0.176364C4.27035 0.0398508 4.65667 -0.0182914 5.04227 0.00579018C5.42787 0.0298718 5.80395 0.135627 6.14559 0.316048C6.48723 0.496469 6.78662 0.747436 7.02393 1.05232L9.82861 4.65544C10.3427 5.31638 10.5239 6.17732 10.3208 6.98982L9.46611 10.4117C9.42192 10.5889 9.42431 10.7746 9.47304 10.9506C9.52178 11.1267 9.6152 11.2871 9.74424 11.4164L13.5833 15.2554C13.7127 15.3847 13.8734 15.4783 14.0498 15.5271C14.2261 15.5758 14.4121 15.578 14.5895 15.5336L18.0099 14.6789C18.4108 14.5786 18.8293 14.5708 19.2337 14.6561C19.6381 14.7414 20.0179 14.9175 20.3442 15.1711L23.9474 17.9742C25.2427 18.982 25.3614 20.8961 24.202 22.0539L22.5864 23.6695C21.4302 24.8258 19.702 25.3336 18.0911 24.7664C13.968 23.3156 10.2244 20.9552 7.13799 17.8601C4.04313 14.7742 1.68269 11.0312 0.231742 6.90857C-0.333883 5.29919 0.173929 3.5695 1.33018 2.41325L2.9458 0.797629Z"
      />
    </svg>
  );
}
