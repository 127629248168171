

import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import React, { useEffect, useRef, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import { ref, getDownloadURL } from "firebase/storage";
import StarsIcon from '../icons/StarsIcon';
import { NavHashLink } from 'react-router-hash-link';
import {AnimatePresence, motion,  useAnimationControls} from "framer-motion";
import PlayIcon from '../icons/PlayIcon';

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
export default function Testimonials({storage}) {
  const [testimonialVideo1, setTestimonialVideo1]=useState()
  const [testimonialVideo2, setTestimonialVideo2]=useState()
  const [testimonialVideo3, setTestimonialVideo3]=useState()
  const [testimonialVideo4, setTestimonialVideo4]=useState()
  const [currentIndex, setCurrentIndex]=useState(0);
  const [prevIndex, setPrevIndex]=useState();
  const [arrowButtons, setArrowButtons]=useState('');
  const [animationDirection, setAnimationDirection]=useState(false)
  const [controls, setControls]=useState(false)
  const videoRef=useRef()
  const carouselAnimation = useAnimationControls()
  const getTestimonialImages=(file_name)=>{
    getDownloadURL(ref(storage, `corso-landing-page/${file_name}`))
    .then((url) => {
      switch(file_name){
        case "Jane Kummel.mov":
          setTestimonialVideo1(url)
          break;
        case 'Keith and Joseph Bowden.mov':
          setTestimonialVideo3(url)
          break; 
        case "Peggy Rogers.mov":
          setTestimonialVideo4(url)
          break;
      }
    })
    .catch((error) => {
      // Handle any errors
    });
  }
  
  const getVideos=()=>{
    getTestimonialImages('Jane Kummel.mov')
    getTestimonialImages('Peggy Rogers.mov')
    getTestimonialImages('Keith and Joseph Bowden.mov')
   
   
  }


  const max_width = useMediaPredicate("(max-width: 897px)");

  let testimonials=[
    {testimonial_video: testimonialVideo1,
      testimonial_quote:"Coming to Corso was the best decision I have ever made",
      client_name:'Jane Kummel',
      subtext:'Resident Since 2022',
      video_link:'Jane Kummel.mov'
    }
    ,
    {testimonial_video: testimonialVideo3,
      testimonial_quote:'I’ve really seen a positive improvement in my parents quality of life',
      client_name:'Keith Bowden',
      subtext:'Son of residents Margarita & Joseph Bowden'
    }
    ,
    {testimonial_video: testimonialVideo4,
      testimonial_quote:'The food is excellent and I’m enjoying myself. I’m glad I made the move.',
      client_name:'Peggy Rogers',
      subtext:'Resident Since 2022'
    }
    
  ]
  const handleControls=()=>{
    
    if (controls===false){
      setControls(true)
      videoRef.current.play()
    }else if (controls===true){
        setControls(false)
        videoRef.current.pause()
      }
    } 
  
  const goToPrevious = ()=>{
    setAnimationDirection(true)
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
    setPrevIndex(currentIndex)
    setCurrentIndex(newIndex);
    setControls(false)
    setArrowButtons(false)
  }
  const goToNext=async()=>{
    setAnimationDirection(false)
    const isLastSlide = currentIndex === testimonials.length-1;
    const newIndex=isLastSlide ? 0 : currentIndex + 1;
    setPrevIndex(currentIndex)
    setCurrentIndex(newIndex)
    setControls(false)
    setArrowButtons(true)
  }

  const getNextBackgroundColor = () => {
    if (arrowButtons === '') {
      return null;
    }
    return arrowButtons ? "black" : "#FFFFFF";
  };

  const getPrevBackgroundColor = () => {
    if (arrowButtons === '') {
      return null;
    }
    return arrowButtons ? "#FFFFFF" : "black";
  };

  
  useEffect(()=>{
    getVideos()
    
  },[])
 return (
  <>
  {max_width?<>
    <div className='testimonial-block'>
      <div className='testimonial-header' >
        <p className='testimonial-header-sub'>
          testimonials
        </p>
        <p className='testimonial-header-text'>
          What Our Clients Say
        </p>
      </div>
      <div className='testimonial-card-container'>
        <AnimatePresence>
        <div className='motion-wrapper div-copy'>
         <motion.div className='testimonial-card div-copy'  transition={{x: { type: "spring", stiffness: 100, damping: 20 }, opacity: { duration: 0.2 }}} 
              drag="x" dragConstraints={{ left: 300, right: 0 }} dragElastic={1} key={currentIndex}
              initial={animationDirection?{ x: -300, opacity: 0 }:{ x: 300, opacity: 0 }} 
              animate={{ x: 0, opacity: 1 }} 
              exit={animationDirection?{ x: 300, opacity: 0 }:{ x: -300, opacity: 0 }}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  goToNext();} else if (swipe > swipeConfidenceThreshold) {goToPrevious();}}}>
          <div className='testimonial-video-div' onClick={handleControls}>
      
          <video alt="" id='myVideo' controls={controls} ref={videoRef} onClick={handleControls} name='testimonial_play' className='testimonial-img' src={`${testimonials[currentIndex].testimonial_video}#t=0.001`} />
              <PlayIcon className='play-icon-mobile'   onClick={handleControls} style={controls?{display:'none', zIndex:'-999 !important', fill:'white'}:{fill:'white', display:'block'}}/>
         
          </div>
         
          <div className='testimonial-card-body'>
            <div className='testimonial-stars'>
            <StarsIcon />
            </div>
            
            <p className='testimonial-card-quote'>
              “{testimonials[currentIndex].testimonial_quote}”
            </p>
            <div className='testimonial-client-info'>
              <p className='testimonial-client-name'>
              {testimonials[currentIndex].client_name}
              </p>
              <p className='testimonial-client-date'>
              {testimonials[currentIndex].subtext}
              </p>
            </div>
          </div>
          
        </motion.div>
        </div>
        </AnimatePresence>
        <div className='testimonial-page-dots'>
          <div className='rectangle654' onClick={()=>setCurrentIndex(0)} style={currentIndex===0?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>
          <div className='rectangle657' onClick={()=>setCurrentIndex(1)} style={currentIndex===1?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>
          <div className='rectangle657' onClick={()=>setCurrentIndex(2)} style={currentIndex===2?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>
        
      </div>
      </div>
      <div className='testimonial-buttons'>
        <div className='left-btn' onClick={goToPrevious} style={{ backgroundColor: getPrevBackgroundColor()}}>
          <div className='left-arrow' >
           <ArrowLeft className='left-arrow-icon' style={{ color: getNextBackgroundColor()}} size={25}/>
          </div>
        </div>
        <div className='left-btn' onClick={goToNext} style={{ backgroundColor: getNextBackgroundColor()}}>
          <div className='right-arrow' >
           <ArrowRight className='right-arrow-icon' style={{ color: getPrevBackgroundColor()}} size={25}/>
          </div>
        </div>
      </div>
      <NavHashLink to='#contactForm' data-testid='testimonials-request-info-btn'>
      <button className="request-info">Request Info</button>
      </NavHashLink>
      
    </div>
  </>:<>


  
  <div className='testimonial-block1'>
      <div className='testimonial-header1 div-copy' >
        <p className='testimonial-header-sub1'>
          Testimonials
        </p>
        <div className='testimonial-header-group1'>
          <p className='testimonial-header-text1'>
            What Our Clients Say
          </p>
          <NavHashLink to='#contactForm' data-testid='testimonials-request-info-btn'>
            <motion.button whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}} className="request-info">Request Info</motion.button>
          </NavHashLink>
          
        </div>
      </div>
      <div className='testimonial-body1 div-copy' >
        <AnimatePresence>
        
         <div className='motion-wrapper div-copy'>
          <motion.div className='testimonial-card1 div-copy' 
              transition={{x: { type: "spring", stiffness: 100, damping: 20 }, opacity: { duration: 0.2 }}} 
              drag="x" dragConstraints={{ left: 300, right: 0 }} dragElastic={1} key={currentIndex}
              initial={animationDirection?{ x: -300, opacity: 0 }:{ x: 300, opacity: 0 }} 
              animate={{ x: 0, opacity: 1 }} 
              exit={animationDirection?{ x: 300, opacity: 0 }:{ x: -300, opacity: 0 }}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  goToNext();} else if (swipe > swipeConfidenceThreshold) {goToPrevious();}}}
              > 

            <div className='testimonial-card-body'>
                <div className='testimonial-stars1'>
                  <StarsIcon />
                </div>
                <div>
                <p className='testimonial-card-quote1'>
                  “{testimonials[currentIndex].testimonial_quote}”
                </p>
                </div>
              
                <div className='testimonial-client-info1'>
                  <p className='testimonial-client-name1'>
                    {testimonials[currentIndex].client_name}
                  </p>
                  <p className='testimonial-client-date1'>
                    {testimonials[currentIndex].subtext}
                  </p>
                </div>
            </div>
            
            <div className='testimonial-video-div' data-testid={`testimonial-video-${currentIndex+1}`}>
              <video id='myVideo' alt="" controls={controls} ref={videoRef} onClick={handleControls} name='testimonial_play' data-testid={`testimonial-video-${currentIndex+1}`} className='testimonial-card-img1' src={`${testimonials[currentIndex].testimonial_video}#t=0.001`} />
              <PlayIcon className='play-icon'   onClick={handleControls} style={controls?{display:'none', fill:'white'}:{fill:'white', display:'block'}} data-testid={`testimonial-video-${currentIndex+1}`}/>
            </div>
            </motion.div>
            
          </div>
          </AnimatePresence>
      
        <div className='testimonial-page-dots1'>
            <div className='rectangle6541'  onClick={()=>setCurrentIndex(0)} style={currentIndex===0?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>
            <div className='rectangle6571'  onClick={()=>setCurrentIndex(1)} style={currentIndex===1?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>
            <div className='rectangle6571'  onClick={()=>setCurrentIndex(2)} style={currentIndex===2?{backgroundColor:"rgba(199, 193, 186, 1)"}:{backgroundColor:"rgba(238, 236, 230, 1)"}}/>

        </div>
        <div className='testimonial-buttons1'>
          <div className='left-btn1' onClick={goToPrevious} style={{ backgroundColor: getPrevBackgroundColor()}} data-testid='testimonial-prev-vid-btn'>
            <div className='left-arrow1'>
            <ArrowLeft size={25} style={{ color: getNextBackgroundColor()}} />
            </div>
          </div>
          <div className='left-btn1' onClick={goToNext} style={{ backgroundColor: getNextBackgroundColor()}} data-testid='testimonial-next-vid-btn'>
            <div className='right-arrow1'>
            <ArrowRight size={25} style={{ color: getPrevBackgroundColor()}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  
  
  </>}
  </>
   
 )
}


