import React from 'react'
import {motion} from "framer-motion";
import { useMediaPredicate } from 'react-media-hook';

export default function RequestInfoBtn() {
  const max_width = useMediaPredicate("(max-width: 760px)");
  return (
    <motion.button  style={{textDecoration:'none !important'}} whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}} className='request-info-btn'>Request Info</motion.button>
  )
}
