export const validEmail=new RegExp(
    /^\S+@\S+\.\S+$/
);

// validPhone is used to match the correct phone number when sending to db (removes country code)
export const validPhone=new RegExp(
    /^\s*[(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g
    // /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
)

// validPhoneForForm is used on form inputs so that users can enter a country code if they want
export const validPhoneForForm=new RegExp(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g
)