
import React, { Fragment, useRef, useState } from 'react'
import {motion} from "framer-motion";
import { useMediaPredicate } from 'react-media-hook';
import { NavHashLink } from 'react-router-hash-link';
import { validEmail, validPhone} from '../Regex';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarBlank, Clock } from 'phosphor-react';
import Select from 'react-select'
import { CaretDown } from '@phosphor-icons/react';
import { addDoc, collection } from 'firebase/firestore';
export default function Quiz({brochureDownload, db, experimentA, setExperimentA}) {
    const [currentIndex, setCurrentIndex]=useState(0);
    const [prevIndex, setPrevIndex]=useState();
    const [finishQuiz, setFinishQuiz]=useState(false)
    const [lastButton, setLastButton]=useState(false)
    const [q1, setQ1]=useState({0:false, 1:false,2:false,3:false,4:false})
    const [q2, setQ2]=useState({0:false, 1:false,2:false,3:false})
    const [q3, setQ3]=useState({0:false, 1:false,2:false,3:false})
    const [q4, setQ4]=useState({0:false, 1:false,2:false,3:false,4:false})
    const [q5, setQ5]=useState({0:false, 1:false,2:false})
    const [q6, setQ6]=useState({0:false, 1:false,2:false,3:false,4:false,5:false,6:false,7:false})
    const [q7, setQ7]=useState('');
    const [q8, setQ8]=useState({});
    const [brochure, setBrochure]=useState(false)
    const current_date=new Date();
    const [progressBar, setProgressBar]=useState(0)
    const [required, setRequired]=useState(false)
    const max_width = useMediaPredicate("(max-width: 814px)");
    const max_width2 = useMediaPredicate("(max-width: 1048px)");
    const [dateAlert, setDateAlert]=useState(false);
    const [phoneAlert, setPhoneAlert]=useState(false);
    const [emailAlert, setEmailAlert]=useState(false);
    const [startDate, setStartDate] = useState();
    const [checkToTextInput, setCheckToTextInput]=useState(false)
    const reactSelectRef=useRef(null);
    const reactSelectRef2=useRef(null);
   

    const reactSelectTimes=[{value:'8:00am',label:'8:00am'},{value:'8:30am',label:'8:30am'},{value:'9:00am',label:'9:00am'},{value:'9:30am',label:'9:30am'},{value:'10:00am',label:'10:00am'},{value:'10:30am',label:'10:30am'},{value:'11:00am',label:'11:00am'},{value:'11:30am',label:'11:30am'},{value:'12:00pm',label:'12:00pm'},{value:'12:30pm',label:'12:30pm'},
            {value:'1:00pm',label:'1:00pm'},{value:'1:30pm',label:'1:30pm'},{value:'2:00pm',label:'2:00pm'},{value:'2:30pm',label:'2:30pm'},{value:'3:00pm',label:'3:00pm'},{value:'3:30pm',label:'3:30pm'},{value:'4:00pm',label:'4:00pm'},{value:'4:30pm',label:''},{value:'5:00pm',label:'5:00pm'},{value:'5:30pm',label:'5:30pm'},{value:'6:00pm',label:'6:00pm'},{value:'6:30pm',label:'6:30pm'},
            {value:'7:00pm',label:'7:00pm'},{value:'7:30pm',label:'7:30pm'},{value:'8:00pm',label:'8:00pm'}]
    const reactSelectReferred=[{value:'Vaccine Clinic',label:'Vaccine Clinic'},{value:'Family or Friends',label:'Family or Friends'},{value:'Referral Company',label:'Referral Company'},
            {value:'Magazine',label:'Magazine'},{value:'Newspaper',label:'Newspaper'},{value:'Billboard',label:'Billboard'},{value:'Facebook/Instagram',label:'Facebook/Instagram'},{value:'Other ad',label:'Other ad'},{value:'Online',label:'Online'},{value:'Drive by',label:'Drive by'},{value:'Other',label:'Other'}]
    const pages=[
        {value_list:q1,prompt:'I Am Looking For:',check_blurb:false, input_type:'checkbox',options:['Myself','My Parent','My Spouse','A Patient','Someone Else']},
        {value_list:q2,prompt:'When Are You Looking To Move In?',check_blurb:false, input_type:'radio',options:['0-30 Days','30-60 Days','60-90 Days','90+ Days']},
        {value_list:q3,prompt:'What Kind Of Home Are You Looking For?',check_blurb:true, input_type:'checkbox',options:['City Home', '1 Bedroom', '2 Bedroom', 'Studio']},
        {value_list:q4,prompt:'Where Would You Prefer To Live In Our Community?',check_blurb:true, input_type:'checkbox',options:['Corner Unit', 'Near Elevator', 'Near Restaurant', 'First Floor', 'Top Floor']},
        {value_list:q5,prompt:'What Level Of Care Are You Looking For?',check_blurb:true, input_type:'checkbox',options:['Independent Living','Assisted Living','Memory Care']},
        {value_list:q6,other:true,prompt:'What Would You Be Interested In Seeing In Your Community?',check_blurb:true, input_type:'checkbox',options:['Security Gated Community','Social Calendars','Restaurants','Pet-friendly','Transportation','Onsite Maintenance', 'Wellness Support', 'Other']},
        {value_list:q7,prompt:'Do you have additional information to share? (Optional)',check_blurb:false, input_type:'text',options:['']},
        {value_list:q8,prompt:'',check_blurb:false, input_type:'contactSlide',options:['']}
        ];

   // for optimize experiment start
        const handleExperiment=(currentIndex)=>{
            if (currentIndex===0){
                       window.dataLayer.push({
                           event_name:'start_quiz',
                           event:'start_quiz'
                       })
                   }
             if (max_width){
               if (experimentA && currentIndex===0){
                 window.dataLayer.push({
                   event:'experiment-triggered',
                   event_name:'experiment-triggered',
                   experiment:'version A'
                 })
               } else if(!experimentA && currentIndex===0){
                   window.dataLayer.push({
                   event:'experiment-triggered',
                   event_name:'experiment-triggered',
                   experiment:'version B'
                   })
             }
           }
          }
// for optimize experiment end
    
    const handleChange=(e,id)=>{
        // for optimize experiment start
        handleExperiment(currentIndex);
        // for optimize experiment end
        if(pages[currentIndex].options[id]==='Other'){
            if(e.target.value===''){
                setCheckToTextInput(true)
                
            }else{
                const x = e.target.value
                setCheckToTextInput(x)
            }
           
        }
        if(e.target.className==='digital-brochure'){
            setBrochure(true)
        }
        switch(currentIndex){
            case 0:
                setQ1({...q1, [id]:e.target.checked}); break;
            case 1:
                setQ2({...q2, [id]:e.target.checked}); break;
            case 2:
                setQ3({...q3, [id]:e.target.checked}); break;
            case 3:
                setQ4({...q4, [id]:e.target.checked}); break;
            case 4:
                setQ5({...q5,[id]:e.target.checked}); break;
            case 5:
                if(e.target.value!==''){
                    setQ6({...q6,[id]:e.target.checked, 4:checkToTextInput}); break;
                }
                setQ6({...q6,[id]:e.target.checked}); break;
            case 6:
                setQ7(e.target.value);
                break;
            case 7:
                break;
        }
    };

    const handleSubmit=async(e)=>{
        e.preventDefault()
        setProgressBar(100)
        const availableTimeList=[]
        for (let i = 0; i < e.target.availableTime.length; i++){
            availableTimeList.push(e.target.availableTime[i].value)
        }
        //
        window.dataLayer.push({
            event_name:'quiz_submitted',
            event:'quiz_submitted',
            'q1':returnSubmission(pages[0].options,q1),
            'q2':returnSubmission(pages[1].options,q2),
            'q3':returnSubmission(pages[2].options,q3),
            'q4':returnSubmission(pages[3].options,q4),
            'q5':returnSubmission(pages[4].options,q5),
            'q6':returnSubmission(pages[5].options,q6),
        });
        ///
   
        
        //
        try {
            const docRef = await addDoc(collection(db, "quizForms"), {  
            q1:returnSubmission(pages[0].options,q1),
            q2:returnSubmission(pages[1].options,q2),
            q3:returnSubmission(pages[2].options,q3),
            q4:returnSubmission(pages[3].options,q4),
            q5:returnSubmission(pages[4].options,q5),
            q6:returnSubmission(pages[5].options,q6),
            q7:q7,
            q8:{
                full_name:e.target.fullName.value,
                email:e.target.email.value,
                phone:e.target.phoneNumber.value,
                date_to_contact:e.target.contactDate.value,
                times_to_contact:availableTimeList,
                referred_by:e.target.referredBy.value,
                preferred_monthly_budget:e.target.budget.value
            },
            'date_submitted':current_date
          });}catch (error) {
            console.error("Error adding document: ", error);
          };
          try {
            const docRef = await addDoc(collection(db, "mail"), {  
                to:['doemelsydney@gmail.com','referralscorso@corsoatlanta.com','referralscorso@villageparkseniorliving.com'],
            
                message:{
                  subject:'New submission from "Schedule a Call" form',
                  html:`Full Name: ${e.target.fullName.value} <br> Email: ${e.target.email.value}<br> Phone: ${e.target.phoneNumber.value}<br>
                  Preferred Contact Date: ${e.target.contactDate.value}<br> Preferred Contact Times: ${availableTimeList}<br> Referred by: ${e.target.referredBy.value}
                  <br> Preferred Monthly Budget: ${e.target.budget.value}<br> Message: ${e.target.aboutInquirer.value}<br>
                  Date of Submission: ${current_date}<br>Page: Corso Landing Page
                  <h3>Quiz results</h3>
                  <ul>
                    <li><b>I Am Looking For:</b> ${returnSubmission(pages[0].options,q1)}</li>
                    <li><b>When Are You Looking To Move In?</b> ${returnSubmission(pages[1].options,q2)}</li>
                    <li><b>What type of residence are you interested in?</b> ${returnSubmission(pages[2].options,q3)}</li>
                    <li><b>Where Would You Prefer To Live In Our Community?</b> ${returnSubmission(pages[3].options,q4)}</li>
                    <li><b>What Level Of Care Are You Looking For?</b> ${returnSubmission(pages[4].options,q5)}</li>
                    <li><b>What Would You Be Interested In Seeing In Your Community?</b> ${returnSubmission(pages[5].options,q6)}</li>
                    <li><b>Any additional information:</b> ${q7}</li>
                  </ul>
                  `
                }
              });}catch (error) {
                console.error("Error adding document: ", error);
              };
        setFinishQuiz(true)
        if (e.target.digitalBrochure.checked){
            brochureDownload()
        }
    };

    const goToPrevious = ()=>{
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? currentIndex : currentIndex - 1;
        const prog=(newIndex*14.286)
        setProgressBar(prog)
        setTimeout(function() {
            setCurrentIndex(newIndex);
            if (currentIndex !== pages.length-2){
               setLastButton(false)}
               setPrevIndex(currentIndex)
        },350)
      }
      const goToNext=async()=>{
        if (!pages[currentIndex].check_blurb && pages[currentIndex].input_type!=='text'){
            Object.keys(pages[currentIndex].value_list).forEach(function(key) {
                if (pages[currentIndex].value_list[key]) {
                  setRequired(false);
                const prog=(currentIndex+1)*14.286
                  setProgressBar(prog)
                  setTimeout(function() {
                      setCurrentIndex(currentIndex + 1)
                      if (currentIndex === pages.length-2){
                          setLastButton(true)
                          setPrevIndex(currentIndex)
                      }
                  },350)
                }else{
                    return;
                }
              });
        } else if(pages[currentIndex].check_blurb || pages[currentIndex].input_type==='text'){
        const prog=(currentIndex+1)*14.286
        setProgressBar(prog)
        setTimeout(function() {
            setCurrentIndex(currentIndex + 1)
            if (currentIndex === pages.length-2){
                setLastButton(true)
                setPrevIndex(currentIndex)
            }
        },350)
    }
      };

    const returnSubmission=(lst,dict)=>{
    let new_lst=[]
    for (const [key, value] of Object.entries(dict)) {
        if (value){
            new_lst.push(lst[key])
        }}
        return new_lst
    };

    const retakeQuiz=()=>{
        window.location.reload(false);
    };


    const handleDateAlert=((date)=>{
        setStartDate(date)
        current_date.toLocaleDateString()
        date.toLocaleDateString()
         if (current_date <= date){
          setDateAlert(false)
        }else{
          setDateAlert(true)
        }
      });

    const validateEmail = (e,i) => {
        if (!validEmail.test(e.target.value)) {
           setEmailAlert(true);
        }else{
          setEmailAlert(false)
          handleChange(e,i)
        }
      };

    const validatePhone = (e,i) =>{
        if (!validPhone.test(e.target.value)) {
           setPhoneAlert(true);
        }else{
          setPhoneAlert(false)
          handleChange(e,i)
        }
     };
   
     const openReactSelect=()=>{
        reactSelectRef.current.focus();
     };
     const openReactSelect2=()=>{
        reactSelectRef2.current.focus();
     };

  
  return (
    <div className='quiz-container' id='quizContainer'>
    <div className='custom-quiz-body div-copy'  >
        <div className='custom-quiz-header'>
            <div className='custom-quiz-header-text'>
                <h1>One-Minute Quiz</h1>
                
                {max_width ?<>
                    <h1> {currentIndex+1}/8</h1>
                </>:<>
                <h1>step {currentIndex+1}/8</h1>
                </>}
              
                
            </div>

            <div className='quiz-divider'>
                <div style={{width:`${progressBar}%`}} className='quiz-progress-bar'></div>
            </div>
        </div>
        {finishQuiz?<>
        <div className='finish-quiz-div'>
            <div>
            <div className='finish-quiz-heading'>
                <h3>Thank you for completing our quiz</h3>
            </div>
            <div className='finish-quiz-blurb'>
                <p>A team member will contact you shortly with a personalized options based on your quiz results.</p><br/>
                <p>If you have any additional questions in the meantime, please <NavHashLink  className='quiz-contact-us' to='#contactForm'>contact us</NavHashLink> and we will get back to you shortly. If you would like to retake the quiz click the button below.</p>
            </div>
            </div>
            <div className='last-slide-footer'>
                <div className='repeat-quiz-btn' onClick={retakeQuiz} data-testid='retake-quiz-btn'>
                    Retake Quiz
                </div>
                <div className='get-brochure' onClick={brochureDownload} data-testid='download-brochure-btn'>
                    Get Brochure
                </div>
            </div>
        </div>
        </>:<Fragment >
       
        <motion.form className='custom-quiz-options' onSubmit={(e)=>handleSubmit(e)}  >
            <div className='form-container-animation'>
        <div className='custom-quiz-question'>
            {currentIndex>6?<></>:<>
            <h3 className='quiz-question-text'>
                {pages[currentIndex].prompt}
            </h3>
            </>}
            {pages[currentIndex].check_blurb&&<>
            <p className='checkbox-directions'>(Check all that apply or if none apply, click next)</p>
            </>}
            
        </div>
            
            <div className='quiz-options-only'>
                {pages[currentIndex].options.map((p,i)=><Fragment key={`${currentIndex}${i}`}>
                {pages[currentIndex].input_type==='text'&&<>
                    <div className='custom-option'>
                        <textarea id={`q${currentIndex}_option${i}`} placeholder='Add any additional information here...' defaultValue={pages[currentIndex].value_list[i]} name={`q${currentIndex}_option${i}`} onChange={(e)=>handleChange(e,i)}  rows='10' cols='45'/>
                        <label htmlFor={`q${currentIndex}_option${i}`}>{p}</label>
                    </div>
                </>}
                {pages[currentIndex].input_type==='checkbox'&&<>
                {pages[currentIndex].options[i]==='Other'?<>
                <motion.div className='custom-option-checkbox custom-option div-copy' whileHover={max_width?{}:{ scale:1.01 }} whileTap={max_width?{}:{ scale: 0.95}}>
                    {checkToTextInput?<>
                    <input className='other-input' type='text' id='otherInput' name='otherInput' defaultValue='' style={{display:'block',width:'100%', padding:'24px'}} placeholder='Other...' onChange={(e)=>handleChange(e,i)} data-testid={`question${currentIndex}-option${i}-btn`}/>
                    </>:<>
                    <input className={`q${currentIndex}-option${i}`} type='checkbox' id={`q${currentIndex}-option${i}`} name={`q${currentIndex}-option${i}`} defaultChecked={pages[currentIndex].value_list[i]}  onChange={(e)=>handleChange(e,i)} data-testid={`question${currentIndex}-option${i}-btn`}/>
                    <label htmlFor={`q${currentIndex}-option${i}`}>{p}</label>
                    </>}
                    </motion.div>
                </>:<>
                <motion.div className='custom-option-checkbox custom-option div-copy' whileHover={max_width?{}:{ scale:1.01 }} whileTap={max_width?{}:{ scale: 0.95}}>
                        <input className={`q${currentIndex}-option${i}`} type='checkbox' id={`q${currentIndex}-option${i}`} name={`q${currentIndex}-option${i}`} defaultChecked={pages[currentIndex].value_list[i]} onChange={(e)=>handleChange(e,i)} data-testid={`question${currentIndex}-option${i}-btn`}/>
                        <label htmlFor={`q${currentIndex}-option${i}`}>{p}</label>
                    </motion.div>
                </>}
                   
                </>}
                {pages[currentIndex].input_type==='radio'&&<>
                    <motion.div className='custom-option-radio custom-option div-copy' whileHover={max_width?{}:{ scale:1.01 }} whileTap={max_width?{}:{ scale: 0.95}}>
                        <input className={`q${currentIndex}-option${i}`} type='radio' id={`q${currentIndex}-option${i}`} name={`${pages[currentIndex]['prompt']}`} defaultChecked={pages[currentIndex].value_list[i]} onChange={(e)=>handleChange(e,i)} data-testid={`question${currentIndex}-option${i}-btn`}/>
                        <label htmlFor={`q${currentIndex}-option${i}`}>{p}</label>
                    </motion.div>
                </>}
                {pages[currentIndex].input_type==='contactSlide'&&<>
                    <div className='quiz-contact-page'>
                        <div className='quiz-full-name-div'>
                            <label htmlFor='fullName'>Full Name</label>
                            <input className='quiz-full-name' type='text' required id='fullName' name='fullName' placeholder='Full Name' onChange={(e)=>handleChange(e,i)} />
                        </div>
                        <div className='email-phone-div'>
                            <div className='quiz-phone-div'>
                                {/* email alert is here only for styling purposes when it expands */}
                                {!max_width2&&<> {emailAlert?<><p  style={{color:'white'}} className='quiz-contact-alerts' >Enter a valid email</p></>:<></>}</>}
                                <label htmlFor='phoneNumber'>Phone Number</label>
                                {phoneAlert?<><p style={{color:'red'}} className='quiz-contact-alerts'>Enter a valid phone number</p></>:<></>}
                                <input className='quiz-phone' type='tel' id='phoneNumber' name='phoneNumber' placeholder='Phone Number' onChange={(e)=>validatePhone(e,i)} />
                            </div>
                            <div className='quiz-email-div'>
                                {/* phone alert is here only for styling purposes when it expands */}
                                {!max_width2&&<>{phoneAlert?<><p style={{color:'white'}} className='quiz-contact-alerts'>Enter a valid phone number</p></>:<></>}</>}
                                <label htmlFor='email'>Email</label>
                                {emailAlert?<><p style={{color:'red'}} required className='quiz-contact-alerts' >Enter a valid email</p></>:<></>}
                                <input className='quiz-email' type='email' id='email' name='email' placeholder='Email' onChange={(e)=>validateEmail(e,i)} />
                            </div>
                        </div>
                        <div className='date-time-picker-div'>
                            <div className='quiz-date-picker-div'>
                                <label className='' htmlFor='contactDate'>Preferred Date to Contact</label>
                                {dateAlert?<><p style={{color:'red'}}  className='quiz-contact-alerts' >Enter a future date</p></>:<></>}
                                <CalendarBlank className='quiz-calendar-icon' color='rgba(199, 193, 186, 1)' size={20} />
                                <DatePicker className='quiz-date-picker' portalId='root-portal' selected={startDate}  onChange={(date) => handleDateAlert(date)} id='contactDate' name='contactDate' placeholderText='Select Date'/>
                                <CaretDown className='date-caret-icon' size={20} />
                            </div>
                            <div className='quiz-time-picker-div' onClick={openReactSelect}>
                                 {/* date alert is here only for styling purposes when it expands */}
                                 {!max_width2&&<> {dateAlert?<><p style={{color:'white'}} className='quiz-contact-alerts' >Enter a future date</p></>:<></>}</>}
                                <label className='' htmlFor='availableTime'>Available Hours To Contact</label>
                                <Select openMenuOnClick openMenuOnFocus ref={reactSelectRef} className="quiz-time-picker"  placeholder='Select Hours' classNamePrefix='react-select' name='availableTime' id='availableTime' options={reactSelectTimes} isMulti 
                                    styles={{control: (baseStyles, state) => ({...baseStyles,padding:'10px',borderRadius: '8px',borderColor:'rgba(199, 193, 186, 1)'})}}/>
                                <Clock className='quiz-clock-icon' color='rgba(199, 193, 186, 1)' size={20}  />
                                <CaretDown className='time-caret-icon' size={20} onClick={openReactSelect}/>
                            </div>
                        </div>
                        <div className='budget-div'>
                            <label className='' htmlFor='budget'>Preferred Monthly Budget</label>
                            <input className='budget' type='number' id='budget' name='budget' placeholder='Preferred Monthly Budget' onChange={(e)=>handleChange(e,i)}  />
                        </div>
                        <div className='referred-by-div' onClick={openReactSelect2}>
                                <label className='' htmlFor='referredBy'>How did you hear about us?</label>
                                <Select openMenuOnClick openMenuOnFocus required ref={reactSelectRef2} className="referred-by"  placeholder='Select' classNamePrefix='react-select-referred' name='referredBy' id='referredBy' options={reactSelectReferred} 
                                    styles={{control: (baseStyles, state) => ({...baseStyles,padding:'10px',borderRadius: '8px',})}}/>
                                <CaretDown className='time-caret-icon' size={20} onClick={openReactSelect2}/>
                            </div>
                        <div className='quiz-about-you-div'>
                            <label htmlFor='aboutInquirer'>Tell Us About Yourself</label>
                            <textarea  className='quiz-about-you' placeholder='Leave any additional notes here.' id='aboutInquirer' name='aboutInquirer' onChange={(e)=>handleChange(e,i)}  rows='4' cols='5'/>
                        </div>
                       
                        <div className='quiz-brochure-checkbox'>
                            <input type='checkbox' id='digitalBrochure' defaultChecked={brochure} name='digitalBrochure' onChange={(e)=>{handleChange(e, currentIndex)}} className='digital-brochure' data-testid='quiz-receive-brochure-btn'/>
                            <label htmlFor='digitalBrochure'>I want to receive a digital brochure.</label>
                        </div>
                        
                    </div>
                </>}
                    
                
                </Fragment>)}
            </div>
        </div>
        <div className='custom-quiz-footer'>
            {currentIndex==0?<>
                <div className='div-copy prev-btn-quiz prev-btn-quiz-disabled' style={{color:'rgba(199, 193, 186, 1)',border:'1px solid rgba(199, 193, 186, 1)'}} >
                    Back
                </div>
            </>
            :<>
                <motion.div className='div-copy prev-btn-quiz' onClick={goToPrevious}  whileHover={max_width?{}:{ scale:1.02 }} whileTap={max_width?{}:{ scale: 0.8 }} data-testid={`quiz-page-${currentIndex+1}-previous-btn`}>
                    Back
                </motion.div></>}
            {lastButton?<><button className='div-copy submit-quiz-btn' type='submit' data-testid='submit-quiz-btn'>Submit</button></>
            :<>
            <motion.div className='div-copy next-btn-quiz'  whileHover={max_width?{}:{ scale:1.02 }} whileTap={max_width?{}:{ scale: 0.8 }} onClick={goToNext} data-testid={`quiz-page-${currentIndex+1}-next-btn`}>
                Next
            </motion.div></>}
            
        </div>

        </motion.form>
        </Fragment>}
    </div>
    </div>
  )
}
