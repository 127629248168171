import { getDownloadURL, ref } from 'firebase/storage';
import React, { Fragment, useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import { NavHashLink } from 'react-router-hash-link';
import RequestInfoBtn from '../components/RequestInfoBtn';
import {motion} from "framer-motion";
import { ArrowRight } from '@phosphor-icons/react';
import { ArrowUpRight } from 'phosphor-react';

export default function Residences({storage}) {
  const max_width = useMediaPredicate("(max-width: 834px)");
  const [photo, setPhoto]=useState();
  const [hover1, setHover1]=useState('black')
  const [hover2, setHover2]=useState('black')
  const [hover3, setHover3]=useState('black')
  const [hover4, setHover4]=useState('black')
  const [image1, setimage1]=useState()
  const [image2, setimage2]=useState()
  const [image3, setimage3]=useState()
  const [image4, setimage4]=useState()
  const [imgAnimation, setImgAnimation]=useState(false)
  const [buttonColor, setButtonColor]=useState({})
  
  const animationOff=()=>{
    setTimeout(function() {setImgAnimation(false)},200)
  }
  const handlePress=(id)=>{
    setButtonColor({
      ...buttonColor,
      [id]:!buttonColor[id],
    })
  }
  const handleMouseUp=()=>{
    setButtonColor({})
  }
  const residences_list=[
    {image:image1,url:'https://corsoatlanta.com/residences/city-homes/',text:'City Homes',class_name:'cityPhoto1', hover:hover1, alt_tag:'Exterior of Corso Atlanta living facility'}
    ,{image:image2,url:'https://corsoatlanta.com/residences/independent-living',text:'Independent Living',class_name:'cityPhoto2',hover:hover2, alt_tag:'Interior of Corso Atlanta building with abstract wallpaper.'}
    ,{image:image3,url:'https://corsoatlanta.com/residences/assisted-living',text:'Assisted Living',class_name:'cityPhoto3',hover:hover3, alt_tag:'Corso Atlanta interior with a leather couch'}
    ,{image:image4,url:'https://corsoatlanta.com/residences/memory-care',text:'Memory Care',class_name:'cityPhoto4',hover:hover4, alt_tag:'A white orchid'}
  ]
  const getPhotos=(file_name)=>{
    getDownloadURL(ref(storage, `corso-landing-page/residences_page/${file_name}`))
    .then((url) => {
      switch(file_name){
        case "residences_img1.png":
          setimage1(url)
          setPhoto(url)
          break;
        case "residences_img2.png":
          setimage2(url)
          break;
        case "residences_img3.png":
          setimage3(url)
          break; 
        case "residences_img4.png":
          setimage4(url)
          break;
      }
    })
    .catch((error) => {
      // Handle any errors
    });
  };

  const getAllPhotos=()=>{
    getPhotos('residences_img1.png')
    getPhotos('residences_img2.png')
    getPhotos('residences_img3.png')
    getPhotos('residences_img4.png')
  }
  
  const changePhoto=(id)=>{
    setImgAnimation(true)
    animationOff()
    switch(id){
      case 0:
        setPhoto(image1)
        setHover1('#D88366')
        break;
      case 1:
          setPhoto(image2)
          setHover2('#D88366')
          break;
      case 2:
          setPhoto(image3)
          setHover3('#D88366')
          break;
      case 3:
        setPhoto(image4)
        setHover4('#D88366')
        break;
    }
  }
  const onMouseOut=(id)=>{
    switch(id){
      case 0:
        setHover1('black')
        break;
      case 1:
          setHover2('black')
          break;
      case 2:
          setHover3('black')
          break;
      case 3:
        setHover4('black')
        break;
    }
  }
  useEffect(()=>{
    getAllPhotos()
  },[])
 return (
 <>

  <div className='residences'>
      <div className='residences-header'>
        <p className='residences-sub p-copy' >Residences</p>
        <p className='residences-title p-copy'>
          What We Offer
        </p>
      </div>
      <div className='residences-body'>
        {max_width?
        <>
        <div className="residences-cards">
        {residences_list.map((r,i)=><Fragment key={i}>
          <div className="residences-card" >
            <img alt={r.alt_tag} className="residences-card-img" src={r.image} />
            <div className="residences-card-body">
              <div className="residences-card-header">
                <p className="residences-card-title">{r.text}</p>
                
              </div>
              <motion.a  whileHover={max_width?{ scale: 1 }:{scale:1.05,duration:.05}} className="floorplans-btn a-copy" 
                onMouseDown={()=>handlePress(i)} href={r.url} onMouseUp={()=>handleMouseUp()} 
                style={{backgroundColor: buttonColor[i] ? 'black': 'white'}} target="_blank"
                rel="noreferrer" data-testid={`residences-${r.text}-btn`}>
                <p className="floorplans-btn-text" style={{color: buttonColor[i] ? 'white': 'black'}}>view floorplans</p>
                <ArrowUpRight style={{color: buttonColor[i] ? 'white': 'black'}} size={20} fill='bold'/>
              </motion.a>
            </div>
          </div>
          <p className="residences-card-divider"></p>
          </Fragment>)}
          </div>
        </>
        :
        <>
          <motion.img alt="" className='city-homes-image1' src={photo} initial={{opacity:.7}} animate={{opacity:imgAnimation?.7:1}} transition={{duration:.1}}/>
        
          
          <div className='residences-body-text'>
            <div className='residences-divider'></div>
              {residences_list.map((r,i)=><Fragment key={i}>
                
                <a href={r.url} target='_blank' rel='noreferrer' className={`${r.class_name} city-homes-two`} onMouseEnter={()=>changePhoto(i)} onMouseLeave={()=>onMouseOut(i)} data-testid={`residences-${r.text}-btn`}>
                  <p className={`${r.class_name} city-homes`} style={{color:r.hover}}>
                    {r.text}
                  </p>
                    <ArrowRight className='city-homes' size={28} style={{color:r.hover}}/>
                </a>
                <div className='residences-divider'></div>
              </Fragment>)}
              </div>
          </>}
          </div>
      <div>
      <NavHashLink to='#contactForm' data-testid='testimonial-request-info-btn'>
        <RequestInfoBtn/>
      </NavHashLink>
       
      </div>
    </div>
 
 
 </>
  
   
 )
}




