import React from 'react'
import banner_pattern from "../imgs/mobilePattern2.png";
export default function MobileBanner() {
 return (
   <div className='mobile-banner-div'>
   </div>
 )
}


