import React, { useEffect, useRef, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import HeroBlockDesignB from '../hero_block/HeroBlockDesignB';
import ValuesBanner from '../values_banner/ValuesBanner';
import AboutUsBlock from '../about_us_block/AboutUsBlock';
import MobileBanner from '../mobile_banner/MobileBanner';
import Testimonials from '../testimonials/Testimonials';
import CommercialBlock from '../commercial_block/CommercialBlock';
import BlogBlock from '../blog_block/BlogBlock';
import Accolades from '../accolades/Accolades';
import ContactBlock from '../contact_block/ContactBlock';
import SocialHandle from '../social_handle/SocialHandle';
import Footer from '../footer/Footer';
import Residences from '../residences1/Residences1';
import NavBar from '../navbar/NavBar';
import { addDoc, collection } from 'firebase/firestore';
import ShortContactForm from '../short_contact_form/ShortContactForm';


export default function Home({storage, db}) {

    const [downloadBrochure, setDownloadBrochure]=useState()
    const [experimentA, setExperimentA]=useState(false)
    const current_date=new Date()
    const brochureRef=useRef(null)

    const submitQuizForm=async(e)=>{
        e.preventDefault();
        if (e.target.digitalBrochure.checked){
          brochureDownload()
        }
        try {
        const docRef = await addDoc(collection(db, "quizForms"), {
          full_name: e.target.full_name.value,
          email: e.target.email.value,
          phone_number: e.target.phoneNumber.value,
          contact_date:e.target.contactDate.value,
          notes: e.target.notes.value,
          to:['sydney@galerieliving.com', 'referralscorso@corsoatlanta.com','referralscorso@villageparkseniorliving.com'],
          message:{
            subject:'New submission from "Schedule a Call" form',
            html:`Full Name: ${e.target.full_name.value} <br> Email: ${e.target.email.value}<br> Phone: ${e.target.phoneNumber.value}<br> Preferred Contact Date: ${e.target.contactDate.value}<br> Message: ${e.target.notes.value}<br> Date of Submission: ${current_date}<br> Page: Corso Landing Page`
          }
        });
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }

    const brochureDownload=()=>{
      getDownloadURL(ref(storage, 'corso-landing-page/Downloadable_Brochure_Corso.pdf'))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Downloadable_Brochure_Corso.pdf';
          link.click();
          URL.revokeObjectURL(link.href);
        };
        xhr.open('GET', url);
        xhr.send();
        
      })
      .catch((error) => {
        // Handle any errors
      });
  };
  
    
    useEffect(()=>{
     
      window.scrollTo(0,0)
      
    },[])
  return (
   <>
  
     <NavBar navbarBackground={'rgba(238, 236, 230, 1)'}/>
     {downloadBrochure?<>
      <a style={{display:'none'}} ref={brochureRef} href={downloadBrochure}/>
     </>:<></>}
     
        <HeroBlockDesignB brochureDownload={brochureDownload} db={db} setExperimentA={setExperimentA} experimentA={experimentA}/> 
      
        <ValuesBanner text1={'hallmarked by'} italics2={'service.'}/>
       
        <ShortContactForm storage={storage} db={db}/>
        <MobileBanner/>
        <AboutUsBlock storage={storage}/>
        
        <ValuesBanner text1={'distinguished by'} italics2={'design.'}/>
        <Testimonials storage={storage}/>

        <MobileBanner/>
        <Residences storage={storage}/>
     
        <ValuesBanner text1={'excellence in'} italics2={'every detail.'}/>
        <CommercialBlock storage={storage}/>
        <MobileBanner/>
        <BlogBlock storage={storage}/>
 
        <Accolades/>
        <ContactBlock db={db} storage={storage} brochureDownload={brochureDownload}/>
        <MobileBanner/>
        <SocialHandle storage={storage}/>
        <Footer storage={storage} brochureDownload={brochureDownload} footerBackground={'rgba(238, 236, 230, 1)'} buttonColor={'rgba(216, 131, 102, 1)'}/>

</>
  )
}
